import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/alumni-workshops-website/alumni-workshops-website/src/components/workshop-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Continuous Integration With Jenkins`}</h1>
    <h2>{`2022-04-27 7:00pm`}</h2>
    <p><em parentName="p">{`Duration: 1 hour`}</em></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://calendar.google.com/calendar/render?action=TEMPLATE&dates=20220428T020000Z%2F20220428T030000Z&details=In%20this%20session%2C%20you%27ll%20learn%20about%20Jenkins%2C%20one%20of%20the%20most%20popular%20continuous%20integration%20tools.%20We%27ll%20talk%20about%20configuration%20as%20code%20and%20try%20to%20provide%20attendees%20with%20the%20opportunity%20to%20play%20around%20with%20the%20system.&location=http%3A%2F%2Flovelace-labs.adadev.org&text=Jenkins%20and%20Continuous%20Integration%20with%20Jason%20Lantz%20and%20Kevin%20Burnett"
        }}>{`Add To Google Calendar`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://outlook.live.com/calendar/0/deeplink/compose?allday=false&body=In%20this%20session%2C%20you%27ll%20learn%20about%20Jenkins%2C%20one%20of%20the%20most%20popular%20continuous%20integration%20tools.%20We%27ll%20talk%20about%20configuration%20as%20code%20and%20try%20to%20provide%20attendees%20with%20the%20opportunity%20to%20play%20around%20with%20the%20system.&enddt=2022-04-28T03%3A00%3A00%2B00%3A00&location=http%3A%2F%2Flovelace-labs.adadev.org&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=2022-04-28T02%3A00%3A00%2B00%3A00&subject=Jenkins%20and%20Continuous%20Integration%20with%20Jason%20Lantz%20and%20Kevin%20Burnett"
        }}>{`Add to Microsoft Calendar`}</a></li>
    </ul>
    <p>{`Come and learn about Jenkins, one of the most popular continuous integration tools. We'll talk about configuration as code and try to provide attendees with the opportunity to play around with the system.`}</p>
    <h2>{`Jason Lantz & Kevin Burnett`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "300px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/43b80072a317bf77672501c82c045787/f93b5/Kevin-Burnett-thumbnail.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAMFAgT/xAAWAQEBAQAAAAAAAAAAAAAAAAABAgT/2gAMAwEAAhADEAAAAZ3z/XRRIzjVNE8Af//EABsQAAEFAQEAAAAAAAAAAAAAAAEAAgMREiET/9oACAEBAAEFAncbhuaRn4JMwegKCCul/8QAGREAAgMBAAAAAAAAAAAAAAAAAAECEBES/9oACAEDAQE/AdWHSJV//8QAFxEAAwEAAAAAAAAAAAAAAAAAAAESEP/aAAgBAgEBPwGWQxZ//8QAHRAAAQIHAAAAAAAAAAAAAAAAAQASAhARICExgf/aAAgBAQAGPwJBjuy0oTUZt//EABoQAAIDAQEAAAAAAAAAAAAAAAERACExQRD/2gAIAQEAAT8h0jayBgGrbkWCYBn0xkEZUdgGbUK/BMkJ/9oADAMBAAIAAwAAABBrEH7/xAAYEQEBAAMAAAAAAAAAAAAAAAABABARYf/aAAgBAwEBPxA2FumAF//EABoRAQABBQAAAAAAAAAAAAAAAAEAEBEhMWH/2gAIAQIBAT8QzBbc40qz/8QAHBABAAMAAgMAAAAAAAAAAAAAAQARIUFRMYGR/9oACAEBAAE/ECuLodqUGvUBZXKJh3eRI2eCAyRVmSwwYFFNb+y+EumNjiVzCPEyf//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Kevin Burnett thumbnail",
            "title": "Kevin Burnett thumbnail",
            "src": "/static/43b80072a317bf77672501c82c045787/f93b5/Kevin-Burnett-thumbnail.jpg",
            "srcSet": ["/static/43b80072a317bf77672501c82c045787/f93b5/Kevin-Burnett-thumbnail.jpg 300w"],
            "sizes": "(max-width: 300px) 100vw, 300px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<em parentName="p">{`Image: Kevin Burnett`}</em></p>
    <p>{`Jason Lantz and Kevin Burnett have been doing DevOps stuff for several years, collaborating with colleagues across many disciplines, and relaxing with code.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      